import { Component, ViewChild, TemplateRef } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pinnacle-infosite-v2';
  toState = 'state1';
 
  ngOnInit(){
  }
  
  changeState(state: any){
    this.toState = state;
  }

}
