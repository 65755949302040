import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';


@Component({
  selector: 'app-slide10',
  templateUrl: './slide10-location.component.html',
  styleUrls: ['./slide10-location.component.scss']
})
export class Slide10LocationComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggle: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(private router: Router) { }

  ngOnInit() {
  }

  slide9(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/facilities']);
    }, 400);
  }

  slide11(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/establishment']);
    }, 400);
  }

}
