import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserLoginRepository } from '../../../repository/review-app-login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router,NavigationStart} from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BasePath, clientId, clientSecret } from '../../../env';

interface User {
  name: { first: string; last: string; };
  picture: { large: string; };
}

import {
  trigger,
  state,
  style,
  animate,
  transition,
  group
  // ...
} from '@angular/animations';
const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideInOut', [
        state('in', style({height: '*', opacity: 0})),
        transition(':leave', [
            style({height: '*', opacity: 1}),

            group([
                animate(300, style({height: 0})),
                animate('200ms ease-in-out', style({'opacity': '0'}))
            ])

        ]),
        transition(':enter', [
            style({height: '0', opacity: 0}),

            group([
                animate(300, style({height: '*'})),
                animate('400ms ease-in-out', style({'opacity': '1'}))
            ])

        ])
    ])
  ]
})
export class ReviewAppLoginComponent implements OnInit {
  user: User | null = null;
  invalidUser: boolean = false;

  toggle: any;
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggleStatus: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  

  constructor(private loginRepo: UserLoginRepository, private http: HttpClient, private router: Router) { }

  ngOnInit() {
  	this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
   		
      }
   	})
  }

  async onClickSubmit(data) {
    this.toggle = "loading";
    let headers = new HttpHeaders({
	    'x-client-id': clientId,
	    'x-client-secret': clientSecret });
	  let options = { headers: headers };
      this.http.post<any>(`${BasePath}/auth/login`, { email: data.userEmail, password: data.userPassword },  options)
      .subscribe(
      	data => {
          localStorage.setItem('pinnacleReviewUser', JSON.stringify(data));
      		this.router.navigate(['/review/subject-code'])
      	},
      	error => {
          this.toggle = "";
          this.invalidUser = true;
          setTimeout(() => {
            this.invalidUser = false;
          }, 2000);
      	}
      )
   }

   slide2(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/goals']);
    }, 400);
  }
}
