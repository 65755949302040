import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { Router,NavigationStart} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';
import * as moment from 'moment';
import { BasePath, clientId, clientSecret } from '../../../env';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  group
  // ...
} from '@angular/animations';
const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';


@Component({
  selector: 'app-subject-code',
  templateUrl: './subject-code.component.html',
  styleUrls: ['./subject-code.component.scss'],
  animations: [
    trigger('slideInOut', [
        state('in', style({height: '*', opacity: 0})),
        transition(':leave', [
            style({height: '*', opacity: 1}),

            group([
                animate(300, style({height: 0})),
                animate('200ms ease-in-out', style({'opacity': '0'}))
            ])

        ]),
        transition(':enter', [
            style({height: '0', opacity: 0}),

            group([
                animate(300, style({height: '*'})),
                animate('400ms ease-in-out', style({'opacity': '1'}))
            ])

        ])
    ])
  ]
})
export class ReviewAppSubjectCodeComponent implements OnInit {
  toggle: any;
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggleStatus: any;
  userFullName: string = '';
  errorMessage: string = '';
  successMessage: string = '';
  expirationDate: string = '';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
  	this.validateUser()
  }

  async validateUser() {
  	if (localStorage.getItem("pinnacleReviewUser") == null) {
  		this.router.navigate(['/review/login'])
	}else{
	  let headers = await new HttpHeaders({
	    'x-client-id': clientId,
	    'x-client-secret': clientSecret,
	    'token': JSON.parse(localStorage.getItem("pinnacleReviewUser")).token
	  });
	  let options = { headers: headers };
      this.http.get<any>(`${BasePath}/auth/sessions/validate`, options)
      .subscribe(
      	data => {
          // Valid token
          const currentUser = JSON.parse(localStorage.getItem("pinnacleReviewUser")).user;
          this.userFullName = `${currentUser.firstName} ${currentUser.lastName}` 
      	},
      	error => {
      		localStorage.clear();
      		this.router.navigate(['/review/login'])
      	}
      )
	}
  }

  async onClickSubmit(data) {
    this.toggle = "loading";
    this.errorMessage = "";
    this.successMessage = "";
    this.expirationDate = "";
    let headers = new HttpHeaders({
	    'x-client-id': clientId,
	    'x-client-secret': clientSecret,
	    'token': JSON.parse(localStorage.getItem("pinnacleReviewUser")).token
	});
	  let options = { headers: headers };
      this.http.post<any>(`${BasePath}/subjects/codes/activate`, { subjectCode: data.subjectCode },  options)
      .subscribe(
      	data => {
      		this.toggle = "";

      		this.successMessage = data.result.message;
      		this.expirationDate = moment(data.result.expiresAt).format('LL')
      		setTimeout(() => {
		   		this.successMessage = "";
	      	}, 6000);
      	},
      	err => {
          this.toggle = "";
          this.errorMessage = err.error.message;
          setTimeout(() => {
		   	this.errorMessage = "";
	      }, 2000);
      	}
      )
   }

   async onClickLogout(){
   	this.toggle = "loading";
	setTimeout(() => {
	   	localStorage.clear();
		this.router.navigate(['/review/login'])
    }, 2000);
   }

}
