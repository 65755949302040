import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';


@Component({
  selector: 'app-slide11-nearby',
  templateUrl: './slide11-nearby.component.html',
  styleUrls: ['./slide11-nearby.component.scss', './ng-masonry-grid.css']
})
export class Slide11NearbyComponent implements OnInit {
 @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggle: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  lists: any;
  masonryItems: any;
  constructor(private router: Router) { }

  ngOnInit() {

    /*this.masonryItems = [
      { title: 'item 1' },
      { title: 'item 2' },
      { title: 'item 3' },
      { title: 'item 4' },
      { title: 'item 5' },
      { title: 'item 6' },
      { title: 'item 6' },
      { title: 'item 6' },
      { title: 'item 6' }
    ];*/
    this.lists = [
      {"id": "1", "name": "Residences", "image": "/assets/admin/img/icons/blue/residences.png", "description":"Affordable condos, dorms, apartments, boarding houses, townhouses "},
      {"id": "2", "name": "Security", "image": "/assets/admin/img/icons/blue/security.png", "description":"100 meters away from Quezon City Police District and Barangay Hall "},
      {"id": "3", "name": "Coffee", "image": "/assets/admin/img/icons/blue/coffee.png", "description":"Starbucks, Seattle's Best, Jitters Coffee Shop, Empire Fashion Café, The Nook Café, Bo's Coffee, SGD Coffee, Hillcrest Wellness Café, Cool Beans, Hue Café, Black Scoop, Theo's Baked + Brewed, Isabel's Coffee House, Kape DIY Filipino Coffee, Chapter Coffee Roastery and Cafe "},
      {"id": "4", "name": "Tea", "image": "/assets/admin/img/icons/blue/coffee.png", "description":"Moonleaf, Infinitea, Zen Tea, Simple Line, I Love Milktea"},
      {"id": "5", "name": "Food & Resto", "image": "/assets/admin/img/icons/blue/food.png", "description":"Over 100+ budget-friendly to high-end restos, Filipino, Japanese, Chinese, Korean, Mexican, Thai, American, Vietnamese cuisines, Food Parks "},
      {"id": "6", "name": "Banking", "image": "/assets/admin/img/icons/blue/banking.png", "description":"BPI, BDO, RCBC, Eastwest Bank, China Bank, Metrobank,Security Bank "},
      {"id": "7", "name": "Church", "image": "/assets/admin/img/icons/blue/church.png", "description":"Diliman Bible Church INC., Parish "},
      {"id": "8", "name": "Fitness", "image": "/assets/admin/img/icons/blue/fitness.png", "description":"Anytime Fitness, Elorde Fight Factor, Muscle Up  Parkour Gym, Pound for Pound Fitness, Jogging Paths, Basketball Court, Badminton Court "},
      {"id": "9", "name": "Recreation", "image": "/assets/admin/img/icons/blue/recreation.png", "description":"Cinema Centenario, Laruan Board Games, Snack and Ladders, Wagging Tails Dog Café, Manila Cat Café "},
      {"id": "10", "name": "Co-working spaces", "image": "/assets/admin/img/icons/blue/working-space.png", "description":"Hatch Hub, WIP PH, Think Spot "},
      {"id": "11", "name": "Internet", "image": "/assets/admin/img/icons/blue/internet.png", "description":"Mineski Infinity, GG80 Internet Café, Heets the Net, SpyNet "},
      {"id": "12", "name": "Others", "image": "/assets/admin/img/icons/blue/others.png", "description":"Quick Clean, Savemore, Shopwise, 7-Eleven, Mini Stop "}
    ]
  }

  slide10(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/location']);
    }, 400);
  }

  slide12(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/accomodation']);
    }, 400);
  }

}
