import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  // @Input() data:string;
  // @Input() data:string;
  @Output() data1: String;
  constructor() {
    console.log(">> " + this.data1)
  }

  ngOnInit() {
  }

}
