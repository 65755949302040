import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'app-slide3',
  templateUrl: './slide3-concepts.component.html',
  styleUrls: ['./slide3-concepts.component.scss']
})
export class Slide3ConceptsComponent implements OnInit {

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggle: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  slide2(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/goals']);
    }, 400);
  }
  slide4(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/reviewers']);
    }, 400);
  }

  goToContact(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/contact']);
    }, 400);
  }

}
