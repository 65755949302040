export const BasePath = 'http://159.65.8.232:8080';

export const currentEnv = 'prod';

export const creds = {
    clientId: {
        uat: 'Jeyk',
        prod: '1beca258-e8ac-4e32-b32f-72a86e265017'
    },
    clientSecret: {
        uat: 'Qwe12345',
        prod: 'UCFubkBjbGVDUEEyMDIw',
    }
};

export const clientId = currentEnv === 'prod' ? creds.clientId.prod : creds.clientId.uat
export const clientSecret = currentEnv === 'prod' ? creds.clientSecret.prod : creds.clientSecret.uat
