import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'app-slide4',
  templateUrl: './slide4-reviewers.component.html',
  styleUrls: ['./slide4-reviewers.component.scss']
})
export class Slide4ReviewersComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggle: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(private router: Router) { }

  ngOnInit() {
  }

  slide3(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/concepts']);
    }, 400);
  }

  slide5(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/owner']);
    }, 400);
  }

}
