import { Injectable } from '@angular/core';

@Injectable()
export class UserLoginRepository {

  async random() {
    const resp = await fetch('https://randomuser.me/api/');
    const data = await resp.json();
    const { results: [user] } = data;
    return user;
  }

  async postLogin(data) {
  	console.log(data)
  	const resp = await fetch('https://randomuser.me/api/');
    // const data = await resp.json();
  }
}