import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';


@Component({
  selector: 'app-slide9',
  templateUrl: './slide9-facilities.component.html',
  styleUrls: ['./slide9-facilities.component.scss']
})
export class Slide9FacilitiesComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggle: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

constructor(private router: Router) { }
  @ViewChild('myModal') myModal: ElementRef;
  imageUrl: string;
  ngOnInit() {
  }

  slide8(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/mobile-application']);
    }, 400);
  }

  slide10(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/location']);
    }, 400);
  }

  showModal(url){
    var modal = document.getElementById('myModal');

    var img = document.getElementById('imageId');
    var modalImg = document.getElementById("imgContent");
    modal.style.display = "block";
    this.imageUrl = url;

  }

  closeModal(){
    var modal = document.getElementById('myModal');
    modal.style.display = "none";
  }


}
