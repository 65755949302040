import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'app-slide12-accomodation',
  templateUrl: './slide12-accomodation.component.html',
  styleUrls: ['./slide12-accomodation.component.scss']
})
export class Slide12AccomodationComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  toggle: any;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(private router: Router) { }
    @ViewChild('myModal') myModal: ElementRef;
    imageUrl: string;

  ngOnInit() {
  }

  slide11(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/establishment']);
    }, 400);
  }

  slide13(){
    this.toggle = "loading";
    setTimeout(() => {
      this.router.navigate(['/plan-pricing']);
    }, 400);
  }

  showModal(url){
    var modal = document.getElementById('myModal');

    var img = document.getElementById('imageId');
    var modalImg = document.getElementById("imgContent");
    modal.style.display = "block";
    this.imageUrl = url;

  }

  closeModal(){
    var modal = document.getElementById('myModal');
    modal.style.display = "none";
  }

}
