import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {Location, LocationStrategy, PathLocationStrategy, HashLocationStrategy} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { Browser } from '../../node_modules/protractor';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { NgMasonryGridModule } from 'ng-masonry-grid';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Slide1HomeComponent } from './components/slide1-home/slide1-home.component';
import { Slide2GoalsComponent } from './components/slide2-goals/slide2-goals.component';
import { Slide3ConceptsComponent } from './components/slide3-concepts/slide3-concepts.component';
import { Slide4ReviewersComponent } from './components/slide4-reviewers/slide4-reviewers.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { Slide5OwnerComponent } from './components/slide5-owner/slide5-owner.component';
import { Slide6PartnersComponent } from './components/slide6-partners/slide6-partners.component';
import { Slide7ProgramsComponent } from './components/slide7-programs/slide7-programs.component';
import { Slide8MobileAppComponent } from './components/slide8-mobile-app/slide8-mobile-app.component';
import { SampleAnimateComponent } from './components/sample-animate/sample-animate.component';
import { Slide9FacilitiesComponent } from './components/slide9-facilities/slide9-facilities.component';
import { Slide10LocationComponent } from './components/slide10-location/slide10-location.component';
import { Slide11NearbyComponent } from './components/slide11-nearby/slide11-nearby.component';
import { Slide12AccomodationComponent } from './components/slide12-accomodation/slide12-accomodation.component';
import { Slide13PricingComponent } from './components/slide13-pricing/slide13-pricing.component';
import { LoaderComponent } from './components/loader/loader.component';
import { Slide14ContactComponent } from './components/slide14-contact/slide14-contact.component';
import { AnnouncementComponent } from './components/frontend/announcement/announcement/announcement.component';
import { ReviewAppLoginComponent } from './components/review-app/login/login.component';
import { ReviewAppSubjectCodeComponent } from './components/review-app/subject-code/subject-code.component'

import { UserLoginRepository } from './repository/review-app-login';

@NgModule({
  declarations: [
    AppComponent,
    Slide1HomeComponent,
    Slide2GoalsComponent,
    Slide3ConceptsComponent,
    Slide4ReviewersComponent,
    SideNavComponent,
    Slide5OwnerComponent,
    Slide6PartnersComponent,
    Slide7ProgramsComponent,
    Slide8MobileAppComponent,
    SampleAnimateComponent,
    Slide9FacilitiesComponent,
    Slide10LocationComponent,
    Slide11NearbyComponent,
    Slide12AccomodationComponent,
    Slide13PricingComponent,
    LoaderComponent,
    Slide14ContactComponent,
    AnnouncementComponent,
    ReviewAppLoginComponent,
    ReviewAppSubjectCodeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    NgMasonryGridModule,
    HttpClientModule 
  ],
  providers: [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}, UserLoginRepository],
  bootstrap: [AppComponent]
})
export class AppModule {
  location: Location;
  constructor(location: Location) { this.location = location; }
}
