import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Slide1HomeComponent } from './components/slide1-home/slide1-home.component';
import { Slide2GoalsComponent } from './components/slide2-goals/slide2-goals.component';
import { Slide3ConceptsComponent } from './components/slide3-concepts/slide3-concepts.component';
import { Slide4ReviewersComponent } from './components/slide4-reviewers/slide4-reviewers.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { Slide5OwnerComponent } from './components/slide5-owner/slide5-owner.component';
import { Slide6PartnersComponent } from './components/slide6-partners/slide6-partners.component';
import { Slide7ProgramsComponent } from './components/slide7-programs/slide7-programs.component';
import { Slide8MobileAppComponent } from './components/slide8-mobile-app/slide8-mobile-app.component';
import { Slide9FacilitiesComponent } from './components/slide9-facilities/slide9-facilities.component';
import { Slide10LocationComponent } from './components/slide10-location/slide10-location.component';
import { Slide11NearbyComponent } from './components/slide11-nearby/slide11-nearby.component';
import { Slide12AccomodationComponent } from './components/slide12-accomodation/slide12-accomodation.component';
import { Slide13PricingComponent } from './components/slide13-pricing/slide13-pricing.component';
import { Slide14ContactComponent } from './components/slide14-contact/slide14-contact.component';
import { ReviewAppLoginComponent } from './components/review-app/login/login.component';
import { ReviewAppSubjectCodeComponent } from './components/review-app/subject-code/subject-code.component';

const routes: Routes = [
  { path : '', component: Slide1HomeComponent },
  { path : 'goals', component: Slide2GoalsComponent },
  { path : 'concepts', component: Slide3ConceptsComponent },
  { path : 'reviewers', component: Slide4ReviewersComponent },
  { path : 'owner', component: Slide5OwnerComponent },
  { path : 'partners', component: Slide6PartnersComponent },
  { path : 'flexible-programs', component: Slide7ProgramsComponent },
  { path : 'mobile-application', component: Slide8MobileAppComponent },
  { path : 'facilities', component: Slide9FacilitiesComponent },
  { path : 'location', component: Slide10LocationComponent },
  { path : 'establishment', component: Slide11NearbyComponent },
  { path : 'accomodation', component: Slide12AccomodationComponent },
  { path : 'plan-pricing', component: Slide13PricingComponent },
  { path : 'contact', component: Slide14ContactComponent },
  { path: 'review/login', component: ReviewAppLoginComponent },
  { path: 'review/subject-code', component: ReviewAppSubjectCodeComponent }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes)
    // RouterModule.forRoot(routes, {
    //   useHash: true,
    // })
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
